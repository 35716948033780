
.splash-container {
    max-width: 850px;
    border: solid 1px;
    margin: auto;
    text-align: center;
    background-color: #ffbb11;
}

.splash-font-info {
    font-size: medium;
    font-weight: 800;
    font-family: Montserrat;
}

.splash-textpane-width {
    width: 220px;
}

.splash-margins {
    margin: auto;
}

.splash-margin-top {
    margin-top: 25px;
}

.splash-img-margins{
    margin-left: 20px;
    margin-top: 10px;
}

.splash-margin-bottom {
    margin-bottom: 20px;
}

.splash-shop-local-img {
    width: 60%;
}

.splash-ellocale-img {
    float: left;
    max-width: 100px;
}

.splash-upmargin {
    top: -20px;
    position: relative;
}