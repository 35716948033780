
.support-centered {
    text-align: center;
}

.support-font-info {
    font-family: Montserrat;
    font-weight: 800;
}

.support-margin-top {
    margin-top: 10px;
}

.support-font-size {
    font-size: small;
}

.support-container-bg {
    background-color: #f6aa2c;
}