
.header {
    width: 100%;
    height: 40px;
    background-color: black;
    border: solid 1px;
    color: #f6aa2c;
}

.footer-icon {
    width: 36px;
    height: 36px;
    margin-left: 30px;
}

.footer-elloc {
    width: 100px;
    position: relative;
    top: 10px;
    margin-right: 20px;
}

.search {
    height: 26px;
    position: relative;
    top: 3px;
    border-radius: 5px;
    width: 300px;
}

.footer-centered {
    margin-left: auto;
    margin-right: auto;
    width: 800px;
    text-align: center;
    display: flex;
}

.footer-other {
    position: relative;
    top: 10px;
    margin-left: 50px;
}

.footer-farright {
    position: relative;
    left: 30px;
}