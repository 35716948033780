.footer {
    width: 100%;
    background-color: lightgray;
    color: #FFFFFF;
    margin-top: auto;
    font-family: Montserrat;
    border-top: solid 1px black;
    padding-top: 10px;
    text-align: center;
}

.footer-icon {
    width: 36px;
    height: 36px;
    margin-left: 30px;
}

.footer-elloc {
    width: 100px;
    position: relative;
    top: 10px;
    margin-right: 20px;
}

.footer-links {
    font-size: 12px;
}

.footer-block {
    text-align: center;
    margin-top: 10px;
}

.footer-padtop {
    padding-top: 5px;
}

.footer-title {
    color: black;
    font-weight: 700;
}

.footer-other {
    position: relative;
    top: 10px;
    margin-left: 50px;
}

.footer-farright {
    position: relative;
    left: 30px;
}